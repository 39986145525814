.linksListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.linkListBox {
  display: flex;
  flex-direction: column;
  width: 80%;
  border: 1px solid #ffaa00;
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 40px;
}
.linkListIcon {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.linkListSitename {
  color: #ffaa00;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 32px;
}
.linkListMylink {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.LinksList_a1 {
  color: #fff;
  font-size: 24px;
}
.linkListSitebox {
  display: flex;
  gap: 12px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.LinksList_img1 {
  width: 32px;
  height: 32px;
}
