.HomePage_img1 {
  display: flex;
}
.heroImageWrapper {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .linksPageNotice,
  .LinksList_a1 {
    font-size: 20px !important;
  }
}
.HomePage_div1 {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c0c0c0;
  text-align: center;
}
