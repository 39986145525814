@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'AngerStyles';
  src: url('./assets/fonts/AngerStyles.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
