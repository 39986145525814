.linksPageWrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
}
.linksPageTop {
  font-family: AngerStyles, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 700;
  color: #ffaa00;
}
.linksPageNotice {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: AngerStyles, sans-serif;
  font-size: 30px;
  color: #c0c0c0;
  font-weight: 600;
  margin-bottom: 44px;
  align-content: center;
}
.linkListMylink {
  display: flex;
  justify-content: center;
}
