.HeaderContent {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  justify-content: center;
  color: #000;
  flex-wrap: wrap;
}
.HeaderWrapper {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  color: #000;
}
.HeaderNav {
}
.Header_a1 {
  color: #feeece;
  margin-right: 24px;
  font-size: 32px;
  margin-left: 24px;
  font-weight: 700;
  font-family: AngerStyles, sans-serif;
}
